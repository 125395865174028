<template>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'id'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="identifier" :header="$t('overview.identifier')" :sortable="true" style="width: 15%"></Column>
            <Column field="title" :header="$t('overview.title')" :sortable="true" style="width: 25%"></Column>
            <Column :header="$t('form.description')" style="width: 55%">
                <template #body="slotProps">
                    {{
                        //eslint-disable-next-line
                        (slotProps.data.description.length <= 50 ? slotProps.data.description : (slotProps.data.description.substr(0, 50) + '...'))
                    }}
                </template>
            </Column>
        </template>

        <template #form>
            <InfoForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <!--<button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;Volgende<span id="nextStep">2</span></button>-->
        </template>
    </Overview>
  <div class="footer-buttons mb-2">
    <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import infoService from '@/services/InfoService.js';
import InfoForm from '@/components/forms/InfoForm.vue';

export default {
    name: 'InfoTexts',
    components: {
        Overview,
        Column,
        InfoForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: infoService,
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>