<template>
    
    <div class="row">
        <label for="identifier" class="col-sm-3 col-form-label required-label">{{$t('overview.identifier')}}</label>
        <div class="col-sm-9">
            <input v-model="infoBubble.identifier" type="text" id="identifier" />
        </div>
    </div>
    <div class="row">
        <label for="title" class="col-sm-3 col-form-label required-label">{{$t('overview.title')}}</label>
        <div class="col-sm-9">
            <input v-model="infoBubble.title" type="text" id="title" />
        </div>
    </div>
    <div class="row">
        <label for="contents" class="col-sm-3 col-form-label required-label">{{$t('form.description')}}</label>
        <div class="col-sm-9">
            <textarea v-model="infoBubble.description" id="contents" rows="10"></textarea>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfoForm',
        props: ['itemData'],
        data() {
            return {
                infoBubble: {
                    'identifier': '',
                    'title': '',
                    'description': ''
                },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.infoBubble = val    
            },
        },
        methods: {
            validate(){
                let infoBubble = this.infoBubble;
                return infoBubble.identifier && infoBubble.title && infoBubble.description;
            },
            getData() {
                return this.infoBubble;
            },
            setData(data) {
                this.infoBubble = data
            },
            reset() {
                this.infoBubble = {
                    'identifier': '',
                    'title': '',
                    'description': ''
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted () {
            this.reset()
           
        }
    }
</script>
